<template>
  <div class="all-online-tests-page">
    <v-row justify="center">
      <v-col md="6">
        <div>
          <!-- view previous test results -->
          <v-card
            class="mx-3 mt-3 py-2"
            color="primary"
            dark
            @click="viewPreviousTestResultsOrReports"
          >
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-book-open</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-wrap"
                  >View Previous Test {{currentUser.is_student ? 'Results' : 'Reports'}}</v-list-item-title
                >
                <v-list-item-subtitle class="text-wrap"
                v-if="currentUser.is_student"
                  >See what you scored in your previous
                  tests</v-list-item-subtitle
                >
                <v-list-item-subtitle class="text-wrap"
                v-else-if="currentUser.is_admin ||  currentUser.is_faculty"
                  >View the reports of previously conducted online tests</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-arrow-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </div>
        <div>
          <intract-smart-list
            class="mt-2"
            ref="all-online-tests-smart-list"
            :endpoint="onlineTestsEndpoint"
            paginated
          >
            <template v-slot:list-item="{ item: test }">
              <v-card
                class="mx-3 mb-2"
                :to="{ name: 'TestDetails', params: { testId: test.id } }"
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-subtitle class="primary--text"
                      >{{ test.subject.name }} |
                      {{ test.subject.room.sections }}</v-list-item-subtitle
                    >
                    <v-list-item-title>{{ test.tname }}</v-list-item-title>
                    <v-list-item-subtitle class="font-weight-medium"
                      ><span
                        v-if="
                          moment(test.startsAt).date() ==
                          moment(test.endsAt).date()
                        "
                        >{{ moment(test.startsAt).format("lll") }} -
                        {{ moment(test.endsAt).format("hh:mm A") }}</span
                      ><span v-else
                        >{{ moment(test.startsAt).format("lll") }} -
                        {{ moment(test.endsAt).format("lll") }}</span
                      ></v-list-item-subtitle
                    >
                    <v-list-item-subtitle>{{
                      test.description
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action
                    ><v-icon color="primary"
                      >mdi-arrow-right</v-icon
                    ></v-list-item-action
                  >
                </v-list-item>
              </v-card>
            </template>
          </intract-smart-list>
        </div>
      </v-col>
    </v-row>
    <!-- select room dialog -->
    <choose-room-dialog
      :visible="showRoomDialog"
      @roomSelected="
        (r) => {
          selectedRoom = r;
          showRoomDialog = false;
        }
      "
    />

    <!-- create test -->
    <intract-create-edit-sheet
    v-if="formFields"
      persistent
      title="Create Online Test"
      :visible="onlineTestCreateSheet.visible"
      @close="
        onlineTestCreateSheet.visible = false;
        onlineTestCreateSheet.editId = null;
      "
      :fields="formFields"
      submit-button-text="Create"
      :endpoint="endpoints.onlineTestViewSet"
      :edit-id="onlineTestCreateSheet.editId"
      :create-success-message="onlineTestCreateSheet.createSuccessMessage"
      :data-object="onlineTestCreateSheet.obj"
      @objectCreated="onlineTestCreated"
      @updateObject="(obj) => (onlineTestCreateSheet.obj = obj)"
    />

    <!-- create test fab -->
    <v-fab-transition>
      <v-btn
        v-if="!isLoading && !currentUser.is_student"
        color="primary"
        @click="onlineTestCreateSheet.visible = true"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import ChooseRoomDialog from "@components/dialogs/ChooseRoomDialog";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import moment from "moment";
import EventBus from "@utils/event_bus";
export default {
  name: "AllOnlineTests",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    ChooseRoomDialog,
    IntractSmartList,
    IntractCreateEditSheet,
  },
  data() {
    return {
      moment,
      selectedRoom: null,
      showRoomDialog: false,
      onlineTestCreateSheet: {
        visible: false,
        createSuccessMessage: "Online Test scheduled successfully!",
        editId: null,
        obj: {
          module: this.$route.params.moduleId,
          faculty: null,
        },
      },
    };
  },
  computed: {
    shouldProceed() {
      return (
        ((this.currentUser.is_admin || this.currentUser.is_student) &&
          this.selectedRoom) ||
        (this.currentUser.is_faculty && !this.currentUser.is_admin)
      );
    },

    subjectsEndpoint() {
      if (!this.shouldProceed || this.currentUser.is_student) return null;
      console.log("executing subjectsEndpoint AllOnlineTests");
      if (this.currentUser.is_admin)
        return this.Helper.addUrlParams(
          this.endpoints.subjectViewSet,
          "room=" + this.selectedRoom.id
        );
      // if faculty
      return this.Helper.addUrlParams(
        this.endpoints.subjectViewSet,
        "faculty=" + this.currentUser.id
      );
    },

    onlineTestsEndpoint() {
      if (!this.shouldProceed) return null;
      var url = this.Helper.addUrlParams(this.endpoints.onlineTestViewSet, [
        `endsAt__gte=${moment().format(this.Helper.dateURLFormat)}`,
        "ordering=startsAt",
      ]);
      if (this.currentUser.is_admin && this.selectedRoom)
        url = this.Helper.addUrlParams(url, [
          "subject__room=" + this.selectedRoom.id,
        ]);

      if (this.currentUser.is_faculty && !this.currentUser.is_admin)
        url = this.Helper.addUrlParams(url, [
          "subject__faculty__id=" + this.currentUser.id,
        ]);

      return url;
    },

    formFields() {
      if(!this.shouldProceed) return null;

      return {
        tname: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 6,
          max: 255,
        },
        subject: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Subject",
          md: 6,
          required: true,
          endpoint: this.subjectsEndpoint,
          items: [],
          itemText: "name",
          // itemSubtitle: "room.sections",
          getSubtitle: (item) => item.room? item.room.sections : "Extra Subject", 
          itemValue: "id",
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description (optional)",
          md: 12,
          required: false,
          max: 3000,
        },
        startsAt: {
          fieldType: CreateEditFieldTypes.DATETIMEPICKER,
          textModel: null,
          min: new Date(),
          label: "Start Datetime",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              !this.onlineTestCreateSheet.obj.endsAt ||
              moment(this.onlineTestCreateSheet.obj.endsAt).isAfter(
                moment(this.onlineTestCreateSheet.obj.startsAt)
              ) ||
              "Start Datetime should be before End Datetime",

            (v) =>
              !v ||
              moment(this.onlineTestCreateSheet.obj.startsAt).isAfter(
                moment()
              ) ||
              "Start Datetime cannot be before the current time.",
          ],
        },
        endsAt: {
          fieldType: CreateEditFieldTypes.DATETIMEPICKER,
          textModel: null,
          hide: this.onlineTestCreateSheet.obj.startsAt == null,
          min: new Date(this.onlineTestCreateSheet.obj.startsAt),
          label: "End Datetime",
          required: true,
          md: 6,
          customRules: [
            (v) =>
              !v ||
              moment(this.onlineTestCreateSheet.obj.endsAt).isAfter(
                moment(this.onlineTestCreateSheet.obj.startsAt)
              ) ||
              "End Datetime should be after Start Datetime",
          ],
          // valid: () => moment(this.onlineTestCreateSheet.obj.endsAt).isAfter(this.onlineTestCreateSheet.obj.startsAt)
        },
      };
    },
  },
  methods: {
    async viewPreviousTestResultsOrReports() {
      if (this.currentUser.is_admin || this.currentUser.is_student)
        this.$router.push({
          name: "TestResults",
          params: { roomId: this.selectedRoom.id },
        });
      else if (this.currentUser.is_faculty && !this.currentUser.is_admin)
        this.$router.push({ name: "TestResults" });
    },
    async onlineTestCreated(test) {
      await this.onRefresh();
      this.$router.push({ name: "TestDetails", params: { testId: test.id } });
    },
    setListeners() {
      EventBus.$on("online_tests__test_deleted", (id) => {
        if (this.$refs["all-online-tests-smart-list"])
          this.$refs["all-online-tests-smart-list"].removeItem(id);
      });
      EventBus.$on("online_tests__test_updated", (item) => {
        if (this.$refs["all-online-tests-smart-list"])
          this.$refs["all-online-tests-smart-list"].replaceItem(item.id, item);
      });
    },
    async onRefresh() {
      console.log(this.$refs);
      if (this.$refs["all-online-tests-smart-list"])
        this.$refs["all-online-tests-smart-list"].getItems();
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      //kill component
      EventBus.$emit("keep_alive__destroy_view", "AllOnlineTests");
    }
    next();
  },

  created() {
    this.setListeners();
    this.showRoomDialog = this.currentUser.is_admin;
    if (this.currentUser.is_admin || this.currentUser.is_faculty) {
      this.onlineTestCreateSheet.obj.created_by = this.currentUser.id;
    }
  },
};
</script>